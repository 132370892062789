// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconMapView from "../../../../styleguide/icons/IconMapView.res.js";
import * as IconListViewNew from "../../../../styleguide/icons/IconListViewNew.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CreateColocationRfpProjectLocations_Map from "./CreateColocationRfpProjectLocations_Map.res.js";
import * as CreateColocationRfpProjectLocations_Filters from "./CreateColocationRfpProjectLocations_Filters.res.js";
import * as CreateColocationRfpProjectLocations_MapWithLocationsScss from "./CreateColocationRfpProjectLocations_MapWithLocations.scss";
import * as CreateColocationRfpProjectLocations_MapWithLocations_MatchedProviders from "./CreateColocationRfpProjectLocations_MapWithLocations_MatchedProviders.res.js";
import * as CreateColocationRfpProjectLocations_MapWithLocations_NotAvailableProviders from "./CreateColocationRfpProjectLocations_MapWithLocations_NotAvailableProviders.res.js";

var css = CreateColocationRfpProjectLocations_MapWithLocationsScss;

function CreateColocationRfpProjectLocations_MapWithLocations(props) {
  var mobile = props.mobile;
  var goBackUrl = props.goBackUrl;
  var data = props.data;
  var serverSide = props.serverSide;
  var match = data.locationView;
  var tmp;
  var exit = 0;
  if (match === "Map" && mobile) {
    tmp = JsxRuntime.jsxs(Button.make, {
          size: "SM",
          color: "Teal",
          className: css.backToListView,
          onClick: (function (param) {
              data.toggleLocationView("List");
            }),
          children: [
            JsxRuntime.jsx(IconListViewNew.make, {
                  title: "List View New",
                  size: "SM",
                  color: "White"
                }),
            "Back to list view"
          ]
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsxs("div", {
          children: [
            JsxRuntime.jsx("h2", {
                  children: "Step 2: Select Your Data Centers"
                }),
            JsxRuntime.jsx("div", {
                  children: JsxRuntime.jsx(CreateColocationRfpProjectLocations_Filters.make, {
                        filters: data.filters,
                        update: data.setFilters,
                        certifications: props.certifications,
                        providers: props.providersForFilter,
                        reset: data.resetFilters
                      }),
                  className: css.filters
                })
          ],
          className: css.filtersContainer
        });
  }
  var match$1 = data.locationView;
  var tmp$1;
  var exit$1 = 0;
  if (match$1 === "Map") {
    if (mobile) {
      tmp$1 = JsxRuntime.jsx(CreateColocationRfpProjectLocations_Map.make, {
            locations: data.locations,
            unavailableProviderIds: Belt_Array.map(data.unavailableProviders, (function (provider) {
                    return provider.id;
                  })),
            locationSelectedIds: data.locationSelectedIds,
            serverSide: serverSide,
            onCheckLocation: data.onCheckLocation
          });
    } else {
      exit$1 = 1;
    }
  } else if (mobile) {
    tmp$1 = JsxRuntime.jsxs("div", {
          children: [
            data.locations.length !== 0 ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                    children: [
                      JsxRuntime.jsx(CreateColocationRfpProjectLocations_MapWithLocations_MatchedProviders.make, {
                            data: data,
                            goBackUrl: goBackUrl
                          }),
                      data.availableProviders.length !== 0 ? JsxRuntime.jsxs(Button.make, {
                              size: "SM",
                              color: "Teal",
                              className: css.mapViewBtn,
                              onClick: (function (param) {
                                  data.toggleLocationView("Map");
                                }),
                              children: [
                                JsxRuntime.jsx(IconMapView.make, {
                                      title: "Map View",
                                      size: "SM",
                                      color: "White"
                                    }),
                                "View on Map"
                              ]
                            }) : null,
                      JsxRuntime.jsx(CreateColocationRfpProjectLocations_MapWithLocations_NotAvailableProviders.make, {
                            data: data
                          })
                    ]
                  }) : (
                data.filters.cities.length !== 0 ? JsxRuntime.jsx("div", {
                        children: "Locations not found",
                        className: css.typeCity
                      }) : JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx("h2", {
                                children: "Get Started!"
                              }),
                          "Type a city in the search bar above"
                        ],
                        className: css.typeCity
                      })
              ),
            JsxRuntime.jsxs("div", {
                  children: [
                    JsxRuntime.jsx(Button.make, {
                          size: mobile ? "SM" : "MD",
                          color: "Primary",
                          visuallyDisabled: data.locationSelectedIds.length === 0,
                          busy: data.isLoading,
                          onClick: (function (param) {
                              data.saveLocations(data.locationSelectedIds);
                              data.toggleMap();
                            }),
                          children: "Select " + (String(data.locationSelectedIds.length) + " Data Centers")
                        }),
                    JsxRuntime.jsx(Button.make, {
                          size: mobile ? "SM" : "MD",
                          color: "Gray",
                          visuallyDisabled: data.isLoading,
                          onClick: (function (param) {
                              data.toggleMap();
                            }),
                          children: "Cancel"
                        })
                  ],
                  className: css.buttons
                })
          ],
          className: Cx.cx([
                css.locations,
                data.locations.length === 0 ? css.empty : ""
              ])
        });
  } else {
    exit$1 = 1;
  }
  if (exit$1 === 1) {
    tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsxs("div", {
                  children: [
                    data.locations.length !== 0 ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                            children: [
                              JsxRuntime.jsx(CreateColocationRfpProjectLocations_MapWithLocations_MatchedProviders.make, {
                                    data: data,
                                    goBackUrl: goBackUrl
                                  }),
                              JsxRuntime.jsx(CreateColocationRfpProjectLocations_MapWithLocations_NotAvailableProviders.make, {
                                    data: data
                                  })
                            ]
                          }) : (
                        data.filters.cities.length !== 0 ? JsxRuntime.jsx("div", {
                                children: "Locations not found",
                                className: css.typeCity
                              }) : JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsx("h2", {
                                        children: "Add Data Centers Here!"
                                      }),
                                  "Type a city in the search bar above"
                                ],
                                className: css.typeCity
                              })
                      ),
                    JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsx(Button.make, {
                                  size: "MD",
                                  color: "Primary",
                                  visuallyDisabled: data.locationSelectedIds.length === 0,
                                  busy: data.isLoading,
                                  onClick: (function (param) {
                                      data.saveLocations(data.locationSelectedIds);
                                      data.toggleMap();
                                    }),
                                  children: "Select " + (String(data.locationSelectedIds.length) + " Data Centers")
                                }),
                            JsxRuntime.jsx(Button.make, {
                                  size: "MD",
                                  color: "Gray",
                                  visuallyDisabled: data.isLoading,
                                  onClick: (function (param) {
                                      data.toggleMap();
                                    }),
                                  children: "Cancel"
                                })
                          ],
                          className: css.buttons
                        })
                  ],
                  className: Cx.cx([
                        css.locations,
                        data.locations.length === 0 ? css.empty : ""
                      ])
                }),
            JsxRuntime.jsx(CreateColocationRfpProjectLocations_Map.make, {
                  locations: data.locations,
                  unavailableProviderIds: Belt_Array.map(data.unavailableProviders, (function (provider) {
                          return provider.id;
                        })),
                  locationSelectedIds: data.locationSelectedIds,
                  serverSide: serverSide,
                  onCheckLocation: data.onCheckLocation
                })
          ]
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                tmp,
                JsxRuntime.jsx("div", {
                      children: tmp$1,
                      className: css.mapWrapper
                    })
              ],
              className: css.container
            });
}

var make = CreateColocationRfpProjectLocations_MapWithLocations;

export {
  css ,
  make ,
}
/* css Not a pure module */
