// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../components/Heading/H1.res.js";
import * as User from "../../../models/User.res.js";
import * as SignUpForm from "../../../bundles/common/signup/SignUpForm.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as SignUpModalScss from "./SignUpModal.scss";

var css = SignUpModalScss;

function SignUpModal(props) {
  var callback = props.callback;
  var close = props.close;
  var onSignUp = props.onSignUp;
  var setUserData = props.setUserData;
  var handleOnSignedUp = function (user) {
    setUserData(User.toJs(user));
    callback();
    close();
    onSignUp(user);
  };
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H1.make, {
                      children: "Sign Up"
                    }),
                JsxRuntime.jsx(SignUpForm.make, {
                      onSignedUp: handleOnSignedUp,
                      onSignIn: props.onSignIn,
                      form: "SignUpModal"
                    })
              ],
              className: css.signUpContainer
            });
}

var Validate;

var make = SignUpModal;

export {
  css ,
  Validate ,
  make ,
}
/* css Not a pure module */
