// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../libs/ID.res.js";
import * as React from "react";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as GoogleMap from "../../../../bindings/GoogleMap.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as GoogleMapStyles from "../../../../styleguide/styles/GoogleMapStyles.res.js";
import * as InfoBoxProjectMap from "../../../common/locations/InfoBoxProjectMap.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as GoogleMapWithBounds from "../../../../styleguide/components/GoogleMap/GoogleMapWithBounds.res.js";
import * as Global from "Client/common/global";
import * as Api from "@react-google-maps/api";
import * as InfoBoxMultipleProjectMap from "../../../common/locations/InfoBoxMultipleProjectMap.res.js";
import * as CreateColocationRfpProjectLocations_MapScss from "./CreateColocationRfpProjectLocations_Map.scss";

var css = CreateColocationRfpProjectLocations_MapScss;

function getBounds(locations) {
  return Belt_Array.map(locations, (function ($$location) {
                return GoogleMap.Coordinates.fromLocationForProjectsMap($$location);
              }));
}

function reducer(_state, action) {
  if (typeof action !== "object") {
    return {
            selectedLocations: []
          };
  } else {
    return {
            selectedLocations: action._0
          };
  }
}

function CreateColocationRfpProjectLocations_Map(props) {
  var onCheckLocation = props.onCheckLocation;
  var serverSide = props.serverSide;
  var locationSelectedIds = props.locationSelectedIds;
  var unavailableProviderIds = props.unavailableProviderIds;
  var locations = props.locations;
  var match = React.useState(function () {
        return 0;
      });
  var setIndex = match[1];
  var match$1 = React.useReducer(reducer, {
        selectedLocations: []
      });
  var dispatch = match$1[1];
  var state = match$1[0];
  var match$2 = state.selectedLocations.length;
  return JsxRuntime.jsx(Api.LoadScript, {
              googleMapsApiKey: Global.GoogleMapAPI,
              libraries: Global.GoogleMapLibraries,
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsxs(GoogleMapWithBounds.make, {
                          mapContainerClassName: css.mapElement,
                          zoom: locations.length !== 0 ? undefined : 4,
                          center: locations.length === 0 ? ({
                                lat: 39.2002066,
                                lng: -102.9214644
                              }) : undefined,
                          style: GoogleMapStyles.standard,
                          options: {
                            mapTypeControl: false,
                            zoomControl: true,
                            zoomControlOptions: {
                              position: 5
                            },
                            streetViewControl: false,
                            fullscreenControl: false
                          },
                          bounds: locations.length !== 0 ? getBounds(locations) : [],
                          children: [
                            JsxRuntime.jsx(GoogleMap.MarkerClusterer.make, {
                                  averageCenter: true,
                                  enableRetinaIcons: true,
                                  minimumClusterSize: 2,
                                  styles: GoogleMap.MarkerClusterer.Style.$$default,
                                  zoomOnClick: false,
                                  onClick: (function (cluster) {
                                      var map = cluster.getMap();
                                      var size = cluster.getSize();
                                      if (size > 12) {
                                        return map.fitBounds(cluster.getBounds());
                                      }
                                      var markers = cluster.getMarkers();
                                      var locationIds = Belt_Array.map(markers, (function (prim) {
                                              return prim.locationId;
                                            }));
                                      var selectedLocations = Belt_Array.keep(locations, (function ($$location) {
                                              return Belt_Array.some(locationIds, (function (locationId) {
                                                            return ID.eq(locationId, $$location.id);
                                                          }));
                                            }));
                                      setIndex(function (param) {
                                            return 0;
                                          });
                                      dispatch({
                                            TAG: "ShowInfoBox",
                                            _0: selectedLocations
                                          });
                                    }),
                                  children: (function (clusterer) {
                                      return Belt_Array.map(locations, (function ($$location) {
                                                    return JsxRuntime.jsx(GoogleMap.Marker.make, {
                                                                position: {
                                                                  lat: $$location.latitude,
                                                                  lng: $$location.longitude
                                                                },
                                                                onClick: (function () {
                                                                    setIndex(function (param) {
                                                                          return 0;
                                                                        });
                                                                    dispatch({
                                                                          TAG: "ShowInfoBox",
                                                                          _0: [$$location]
                                                                        });
                                                                  }),
                                                                icon: serverSide ? undefined : (
                                                                    (function (__x) {
                                                                          return Js_array.includes($$location.providerId, __x);
                                                                        })(unavailableProviderIds) ? GoogleMap.Marker.Icon.disabled : GoogleMap.Marker.Icon.$$default
                                                                  ),
                                                                clusterer: Caml_option.some(clusterer),
                                                                noClustererRedraw: true,
                                                                onLoad: (function (marker) {
                                                                    marker.locationId = $$location.id;
                                                                  })
                                                              }, ID.toString($$location.id));
                                                  }));
                                    })
                                }),
                            match$2 !== 0 ? (
                                match$2 !== 1 ? JsxRuntime.jsx(InfoBoxMultipleProjectMap.make, {
                                        locations: state.selectedLocations,
                                        locationSelectedIds: locationSelectedIds,
                                        unavailableProviderIds: unavailableProviderIds,
                                        closeInfoBox: (function (param) {
                                            dispatch("CloseInfoBox");
                                          }),
                                        onCheckLocation: onCheckLocation,
                                        index: match[0],
                                        setIndex: setIndex
                                      }) : JsxRuntime.jsx(InfoBoxProjectMap.make, {
                                        location: Belt_Array.getExn(state.selectedLocations, 0),
                                        locationSelectedIds: locationSelectedIds,
                                        unavailableProviderIds: unavailableProviderIds,
                                        closeInfoBox: (function (param) {
                                            dispatch("CloseInfoBox");
                                          }),
                                        onCheckLocation: onCheckLocation
                                      })
                              ) : null
                          ]
                        }),
                    className: css.mapContainer
                  })
            });
}

var defaultLat = 39.2002066;

var defaultLng = -102.9214644;

var make = CreateColocationRfpProjectLocations_Map;

export {
  css ,
  defaultLat ,
  defaultLng ,
  getBounds ,
  reducer ,
  make ,
}
/* css Not a pure module */
