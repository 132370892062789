import * as React from 'react';
import ReduxProvider from 'ReduxProvider';
import { connect } from 'react-redux';
import { setUserData } from 'Client/common/actions/userActions';
import CreateColocationRfpProjectLocations from './CreateColocationRfpProjectLocations__JsBridge.res.js';

const mapDispatchToProps = dispatch => ({
  reduxfunction: {
    setUserData: userData => {
      dispatch(setUserData(userData));
    },
  },
});

const CreateColocationRfpProjectLocationsContainer = connect(
  null,
  mapDispatchToProps,
)(CreateColocationRfpProjectLocations);

export default ({ context, ...props }) => (
  <ReduxProvider>
    <CreateColocationRfpProjectLocationsContainer jsProps={props} railsContext={context} />
  </ReduxProvider>
);
