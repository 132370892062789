// Generated by ReScript, PLEASE EDIT WITH CARE

import * as TextField from "../../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CreateColocationRfpProjectLocations_FilterScss from "./CreateColocationRfpProjectLocations_Filter.scss";

var css = CreateColocationRfpProjectLocations_FilterScss;

function CreateColocationRfpProjectLocations_Filter_Search(props) {
  var update = props.update;
  var filters = props.filters;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(TextField.make, {
                    id: "user-project-locations-filter--search",
                    value: filters.query,
                    placeholder: "Search",
                    disabled: Belt_Array.get(filters.cities, 0) !== undefined,
                    className: css.searchInput,
                    onChange: (function ($$event) {
                        update({
                              providerIds: filters.providerIds,
                              certificationIds: filters.certificationIds,
                              cities: filters.cities,
                              radius: filters.radius,
                              query: $$event.target.value
                            });
                      })
                  }),
              className: css.searchWrapper
            });
}

var make = CreateColocationRfpProjectLocations_Filter_Search;

export {
  css ,
  make ,
}
/* css Not a pure module */
