// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as ID from "../../../../libs/ID.res.js";
import * as Label from "../../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconSelect from "../../../../styleguide/icons/IconSelect.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CreateColocationRfpProjectLocations_SelectLocationListScss from "./CreateColocationRfpProjectLocations_SelectLocationList.scss";

var css = CreateColocationRfpProjectLocations_SelectLocationListScss;

function reducer(state, action) {
  return {
          bodyShown: !state.bodyShown
        };
}

function CreateColocationRfpProjectLocations_SelectLocationList$SelectLocationListItem(props) {
  var disabled = props.disabled;
  var onCheckLocation = props.onCheckLocation;
  var locationSelectedIds = props.locationSelectedIds;
  var __className = props.className;
  var provider = props.provider;
  var className = __className !== undefined ? __className : "";
  var match = React.useReducer(reducer, {
        bodyShown: false
      });
  var dispatch = match[1];
  var state = match[0];
  var id = "user-project-locations-provider" + (ID.toString(provider.id) + "-selectall");
  var locationsCount = provider.locations.length;
  var selectedCount = Belt_Array.reduce(provider.locations, 0, (function (count, $$location) {
          if ((function (__x) {
                  return Js_array.includes($$location.id, __x);
                })(locationSelectedIds)) {
            return count + 1 | 0;
          } else {
            return count;
          }
        }));
  var isEveryChecked = Belt_Array.every(provider.locations, (function ($$location) {
          return Js_array.includes($$location.id, locationSelectedIds);
        }));
  var isSomeChecked = Belt_Array.some(provider.locations, (function ($$location) {
          return Js_array.includes($$location.id, locationSelectedIds);
        }));
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Checkbox.make, {
                              id: id,
                              size: "SM",
                              checked: isEveryChecked,
                              disabled: disabled,
                              className: Cx.cx([
                                    css.locationCheckbox,
                                    isSomeChecked && !isEveryChecked ? css.someChecked : ""
                                  ]),
                              onChange: (function ($$event) {
                                  var checked = $$event.target.checked;
                                  var allLocationIds = Belt_Array.map(provider.locations, (function ($$location) {
                                          return $$location.id;
                                        }));
                                  if (checked) {
                                    return onCheckLocation(Belt_Array.concat(locationSelectedIds, allLocationIds));
                                  } else {
                                    return onCheckLocation(Belt_Array.keep(locationSelectedIds, (function (value) {
                                                      return !(function (__x) {
                                                                  return Js_array.includes(value, __x);
                                                                })(allLocationIds);
                                                    })));
                                  }
                                })
                            }),
                        JsxRuntime.jsx(Label.make, {
                              forId: id,
                              className: css.label,
                              children: provider.name
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("span", {
                                      children: [
                                        String(locationsCount) + (" data " + (
                                            locationsCount !== 1 ? "centers" : "center"
                                          )),
                                        selectedCount !== 0 ? " (" + (String(selectedCount) + " selected)") : null
                                      ]
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(IconSelect.make, {
                                            size: "XS",
                                            color: "LighterGray",
                                            className: Cx.cx([
                                                  css.icon,
                                                  state.bodyShown ? css.flip : ""
                                                ])
                                          }),
                                      onClick: (function (param) {
                                          dispatch("ToggleBody");
                                        })
                                    })
                              ],
                              className: css.locationsCount
                            })
                      ],
                      className: css.blockHeader
                    }),
                state.bodyShown ? JsxRuntime.jsx("div", {
                        children: Belt_Array.map(provider.locations, (function ($$location) {
                                var locationId = "provider-location-" + ID.toString($$location.id);
                                return JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Checkbox.make, {
                                                    id: locationId,
                                                    size: "SM",
                                                    checked: (function (__x) {
                                                          return Js_array.includes($$location.id, __x);
                                                        })(locationSelectedIds),
                                                    disabled: disabled,
                                                    className: css.locationCheckbox,
                                                    onChange: (function ($$event) {
                                                        var checked = $$event.target.checked;
                                                        if (checked) {
                                                          return onCheckLocation(Belt_Array.concat(locationSelectedIds, [$$location.id]));
                                                        } else {
                                                          return onCheckLocation(Belt_Array.keep(locationSelectedIds, (function (value) {
                                                                            return Caml_obj.notequal(value, $$location.id);
                                                                          })));
                                                        }
                                                      })
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    children: JsxRuntime.jsx("img", {
                                                          src: $$location.image
                                                        }),
                                                    className: css.locationImage
                                                  }),
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      JsxRuntime.jsx(Label.make, {
                                                            forId: locationId,
                                                            className: css.label,
                                                            children: $$location.name
                                                          }),
                                                      JsxRuntime.jsx("span", {
                                                            children: $$location.fullAddress
                                                          })
                                                    ],
                                                    className: css.locationName
                                                  })
                                            ],
                                            className: css.locationItem
                                          }, ID.toString($$location.id));
                              })),
                        className: css.blockBody
                      }) : null
              ],
              className: Cx.cx([
                    css.selectBlock,
                    className,
                    disabled ? css.disabledBlock : ""
                  ])
            });
}

var SelectLocationListItem = {
  reducer: reducer,
  make: CreateColocationRfpProjectLocations_SelectLocationList$SelectLocationListItem
};

function CreateColocationRfpProjectLocations_SelectLocationList(props) {
  var __disabled = props.disabled;
  var onCheckLocation = props.onCheckLocation;
  var locationSelectedIds = props.locationSelectedIds;
  var disabled = __disabled !== undefined ? __disabled : false;
  return JsxRuntime.jsx("div", {
              children: Belt_Array.map(props.providers, (function (provider) {
                      return JsxRuntime.jsx(CreateColocationRfpProjectLocations_SelectLocationList$SelectLocationListItem, {
                                  provider: provider,
                                  className: css.itemWrapper,
                                  locationSelectedIds: locationSelectedIds,
                                  onCheckLocation: onCheckLocation,
                                  disabled: disabled
                                }, ID.toString(provider.id));
                    })),
              className: css.wrapper
            });
}

var make = CreateColocationRfpProjectLocations_SelectLocationList;

export {
  css ,
  SelectLocationListItem ,
  make ,
}
/* css Not a pure module */
