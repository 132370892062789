// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconPlus(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Plus";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsxs("g", {
                    children: [
                      JsxRuntime.jsx("path", {
                            d: "M-3-3h20v20H-3z"
                          }),
                      JsxRuntime.jsx("path", {
                            d: "M0 6h6V0h2v6h6v2H8v6H6V8H0z",
                            fill: Icon.mapColor(props.color),
                            fillRule: "nonzero"
                          })
                    ],
                    fill: "none",
                    fillRule: "evenodd"
                  })
            });
}

var make = IconPlus;

export {
  make ,
}
/* Icon Not a pure module */
