// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../styleguide/components/Link/A.res.js";
import * as ID from "../../../libs/ID.res.js";
import * as Label from "../../../styleguide/forms/Label/Label.res.js";
import * as Picture from "../../../styleguide/forms/Picture/Picture.res.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as $$Location from "../../../models/Location.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as InfoBoxLocationScss from "./InfoBoxLocation.scss";

var css = InfoBoxLocationScss;

function InfoBoxProjectLocationTile(props) {
  var onCheckLocation = props.onCheckLocation;
  var locationSelectedIds = props.locationSelectedIds;
  var $$location = props.location;
  var locationdId = "map-location-" + ID.toString($$location.id);
  var isUnavailable = (function (__x) {
        return Js_array.includes($$location.providerId, __x);
      })(props.unavailableProviderIds);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(A.make, {
                      href: $$location.url,
                      targetBlank: true,
                      className: css.location,
                      children: [
                        JsxRuntime.jsx(Picture.make, {
                              src: $$location.image,
                              large: [
                                270,
                                180
                              ],
                              className: css.image,
                              fallbackSrc: $$Location.imageNotFoundUrl
                            }),
                        isUnavailable ? JsxRuntime.jsx("div", {
                                children: "Service Not Available",
                                className: css.locationUnavailable
                              }) : null,
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("section", {
                                      children: $$location.providerName,
                                      className: css.provider
                                    }),
                                JsxRuntime.jsx("section", {
                                      children: $$location.name,
                                      className: css.name
                                    }),
                                JsxRuntime.jsx("section", {
                                      children: $$location.fullAddress,
                                      className: css.address
                                    }),
                                JsxRuntime.jsxs("section", {
                                      children: [
                                        JsxRuntime.jsx(Checkbox.make, {
                                              id: locationdId,
                                              size: "SM",
                                              checked: (function (__x) {
                                                    return Js_array.includes($$location.locationId, __x);
                                                  })(locationSelectedIds),
                                              disabled: isUnavailable,
                                              className: css.locationCheckbox,
                                              onChange: (function ($$event) {
                                                  var checked = $$event.target.checked;
                                                  if (checked) {
                                                    return onCheckLocation(Belt_Array.concat(locationSelectedIds, [$$location.locationId]));
                                                  } else {
                                                    return onCheckLocation(Belt_Array.keep(locationSelectedIds, (function (value) {
                                                                      return Caml_obj.notequal(value, $$location.locationId);
                                                                    })));
                                                  }
                                                })
                                            }),
                                        JsxRuntime.jsx(Label.make, {
                                              forId: locationdId,
                                              className: css.label,
                                              children: "Select location"
                                            })
                                      ],
                                      className: css.selected
                                    })
                              ],
                              className: css.details
                            })
                      ]
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Picture.make, {
                            src: $$location.providerLogo,
                            large: [
                              70,
                              54
                            ],
                            crop: "Fit"
                          }),
                      className: css.logo
                    })
              ]
            });
}

var make = InfoBoxProjectLocationTile;

export {
  css ,
  make ,
}
/* css Not a pure module */
