// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconListViewNew(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "List View New";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M3 8h2a1 1 0 011 1v2a1 1 0 01-1 1H3a1 1 0 01-1-1V9a1 1 0 011-1zm0-6h2a1 1 0 011 1v2a1 1 0 01-1 1H3a1 1 0 01-1-1V3a1 1 0 011-1zm6 0h8a1 1 0 011 1v2a1 1 0 01-1 1H9a1 1 0 01-1-1V3a1 1 0 011-1zm0 6h4a1 1 0 011 1v2a1 1 0 01-1 1H9a1 1 0 01-1-1V9a1 1 0 011-1z",
                    fill: Icon.mapColor(props.color)
                  })
            });
}

var make = IconListViewNew;

export {
  make ,
}
/* Icon Not a pure module */
