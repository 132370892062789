// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Control from "../../../../styleguide/components/Control/Control.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CreateColocationRfpProjectLocations_Filter_More from "./CreateColocationRfpProjectLocations_Filter_More.res.js";
import * as CreateColocationRfpProjectLocations_FilterScss from "./CreateColocationRfpProjectLocations_Filter.scss";
import * as CreateColocationRfpProjectLocations_Filter_Cities from "./CreateColocationRfpProjectLocations_Filter_Cities.res.js";
import * as CreateColocationRfpProjectLocations_Filter_Radius from "./CreateColocationRfpProjectLocations_Filter_Radius.res.js";
import * as CreateColocationRfpProjectLocations_Filter_Search from "./CreateColocationRfpProjectLocations_Filter_Search.res.js";
import * as CreateColocationRfpProjectLocations_Filter_Providers from "./CreateColocationRfpProjectLocations_Filter_Providers.res.js";

var css = CreateColocationRfpProjectLocations_FilterScss;

function CreateColocationRfpProjectLocations_Filters(props) {
  var reset = props.reset;
  var update = props.update;
  var filters = props.filters;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(CreateColocationRfpProjectLocations_Filter_Search.make, {
                            filters: filters,
                            update: update
                          }),
                      className: css.filterRow
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(CreateColocationRfpProjectLocations_Filter_Cities.make, {
                              filters: filters,
                              update: update
                            }),
                        JsxRuntime.jsx(CreateColocationRfpProjectLocations_Filter_Radius.make, {
                              filters: filters,
                              update: update
                            })
                      ],
                      className: css.filterRow
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(CreateColocationRfpProjectLocations_Filter_Providers.make, {
                            filters: filters,
                            update: update,
                            providers: props.providers
                          }),
                      className: css.filterRow
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(CreateColocationRfpProjectLocations_Filter_More.make, {
                              filters: filters,
                              update: update,
                              certifications: props.certifications
                            }),
                        JsxRuntime.jsx(Control.make, {
                              className: css.reset,
                              onClick: (function (param) {
                                  reset();
                                }),
                              children: "Reset"
                            })
                      ],
                      className: css.filterRow
                    })
              ]
            });
}

var make = CreateColocationRfpProjectLocations_Filters;

export {
  css ,
  make ,
}
/* css Not a pure module */
