// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconServers(props) {
  var __opacity = props.opacity;
  var color = props.color;
  var __title = props.title;
  var title = __title !== undefined ? __title : "Servers";
  var opacity = __opacity !== undefined ? __opacity : "1.0";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              viewBoxSize: "40",
              className: props.className,
              children: JsxRuntime.jsxs("g", {
                    children: [
                      JsxRuntime.jsx("path", {
                            d: "M38 0a2 2 0 012 2v8a2 2 0 01-2 2H2a2 2 0 01-2-2V2C0 .9.9 0 2 0h36zm0 2H2v8h36V2zm0 12a2 2 0 012 2v8a2 2 0 01-2 2H2a2 2 0 01-2-2v-8c0-1.1.9-2 2-2h36zm0 2H2v8h36v-8zm0 12a2 2 0 012 2v8a2 2 0 01-2 2H2a2 2 0 01-2-2v-8c0-1.1.9-2 2-2h36zm0 2H2v8h36v-8z"
                          }),
                      JsxRuntime.jsx("path", {
                            d: "M6 8a2 2 0 110-4 2 2 0 010 4zm6 0a2 2 0 110-4 2 2 0 010 4zM6 22a2 2 0 110-4 2 2 0 010 4zm6 0a2 2 0 110-4 2 2 0 010 4zM6 36a2 2 0 110-4 2 2 0 010 4zm6 0a2 2 0 110-4 2 2 0 010 4z"
                          })
                    ],
                    fill: color !== undefined ? Icon.mapColor(color) : "#C3CCD2",
                    fillRule: "nonzero",
                    opacity: opacity
                  })
            });
}

var make = IconServers;

export {
  make ,
}
/* Icon Not a pure module */
