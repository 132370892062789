// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Select from "../../../../styleguide/forms/Select/Select.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CreateColocationRfpProjectLocations_FilterScss from "./CreateColocationRfpProjectLocations_Filter.scss";

var css = CreateColocationRfpProjectLocations_FilterScss;

function CreateColocationRfpProjectLocations_Filter_Radius(props) {
  var update = props.update;
  var filters = props.filters;
  return JsxRuntime.jsxs(Select.make, {
              id: "user-project-locations-filter--radius",
              value: filters.radius,
              disabled: filters.query.length !== 0,
              className: css.radiusFilter,
              wrapperClassName: css.radiusFilterWrapper,
              iconSize: "XS",
              iconColor: "Gray",
              onChange: (function ($$event) {
                  var radius = $$event.target.value;
                  update({
                        providerIds: filters.providerIds,
                        certificationIds: filters.certificationIds,
                        cities: filters.cities,
                        radius: radius,
                        query: filters.query
                      });
                }),
              children: [
                JsxRuntime.jsx(Select.$$Option.make, {
                      value: "0",
                      children: "No radius"
                    }),
                Belt_Array.map([
                      25,
                      50,
                      75,
                      100,
                      200,
                      300,
                      400,
                      500,
                      1000
                    ], (function (radius) {
                        var value = String(radius);
                        return JsxRuntime.jsx(Select.$$Option.make, {
                                    value: value,
                                    children: value + " mi radius"
                                  }, value);
                      }))
              ]
            });
}

var make = CreateColocationRfpProjectLocations_Filter_Radius;

export {
  css ,
  make ,
}
/* css Not a pure module */
