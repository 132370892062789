// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../libs/ID.res.js";
import * as Label from "../../../../styleguide/forms/Label/Label.res.js";
import * as Checkbox from "../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Dropdown from "../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CreateColocationRfpProjectLocations_FilterScss from "./CreateColocationRfpProjectLocations_Filter.scss";

var css = CreateColocationRfpProjectLocations_FilterScss;

function CreateColocationRfpProjectLocations_Filter_Providers(props) {
  var update = props.update;
  var filters = props.filters;
  var x = filters.providerIds.length;
  return JsxRuntime.jsxs(Dropdown.make, {
              className: css.dropdownWrapper,
              children: [
                JsxRuntime.jsx(Dropdown.Trigger.make, {
                      className: css.dropdownTrigger,
                      iconSize: "XS",
                      iconColor: "Gray",
                      children: x !== 0 ? (
                          x !== 1 ? String(x) + " Providers" : "1 Provider"
                        ) : "Provider"
                    }),
                JsxRuntime.jsx(Dropdown.Body.make, {
                      position: {
                        TAG: "Below",
                        _0: "LeftEdge"
                      },
                      className: css.dropdownBody,
                      children: Belt_Array.map(props.providers, (function (provider) {
                              var id = "user-project-locations-filter--provider-" + ID.toString(provider.id);
                              return JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(Checkbox.make, {
                                                  id: id,
                                                  size: "SM",
                                                  checked: (function (__x) {
                                                        return Js_array.includes(provider.id, __x);
                                                      })(filters.providerIds),
                                                  onChange: (function ($$event) {
                                                      var checked = $$event.target.checked;
                                                      var providerIds = checked ? Belt_Array.concat(filters.providerIds, [provider.id]) : Belt_Array.keep(filters.providerIds, (function (value) {
                                                                return ID.toString(value) !== ID.toString(provider.id);
                                                              }));
                                                      update({
                                                            providerIds: providerIds,
                                                            certificationIds: filters.certificationIds,
                                                            cities: filters.cities,
                                                            radius: filters.radius,
                                                            query: filters.query
                                                          });
                                                    })
                                                }),
                                            JsxRuntime.jsx(Label.make, {
                                                  forId: id,
                                                  className: css.label,
                                                  children: provider.name
                                                })
                                          ],
                                          className: css.dropdownBodyRow
                                        }, id);
                            }))
                    })
              ]
            });
}

var make = CreateColocationRfpProjectLocations_Filter_Providers;

export {
  css ,
  make ,
}
/* css Not a pure module */
