// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../../../api/Api.res.js";
import * as Url from "../../../../utils/Url.res.js";
import * as React from "react";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.res.js";
import * as CreateColocationRfpProjectLocations_Data_Filter from "./CreateColocationRfpProjectLocations_Data_Filter.res.js";

function getSelectedIds(cities) {
  var allLocations = Belt_Array.reduce(cities, [], (function (locations, city) {
          return Belt_Array.concat(locations, city.locations);
        }));
  return Belt_Array.reduce(allLocations, [], (function (ids, $$location) {
                return Belt_Array.concat(ids, [$$location.id]);
              }));
}

function reducer(state, action) {
  if (typeof action !== "object") {
    if (action === "ToggleLocationMap") {
      return {
              locationSelectedIds: state.locationSelectedIds,
              availableProviders: state.availableProviders,
              unavailableProviders: state.unavailableProviders,
              locations: state.locations,
              isMapShown: !state.isMapShown,
              isLoading: state.isLoading,
              cities: state.cities,
              filters: state.filters,
              locationView: state.locationView,
              userLoginStatus: state.userLoginStatus,
              errorMessage: state.errorMessage
            };
    } else {
      return {
              locationSelectedIds: state.locationSelectedIds,
              availableProviders: state.availableProviders,
              unavailableProviders: state.unavailableProviders,
              locations: [],
              isMapShown: state.isMapShown,
              isLoading: state.isLoading,
              cities: state.cities,
              filters: state.filters,
              locationView: state.locationView,
              userLoginStatus: state.userLoginStatus,
              errorMessage: state.errorMessage
            };
    }
  }
  switch (action.TAG) {
    case "FetchCities" :
        return {
                locationSelectedIds: state.locationSelectedIds,
                availableProviders: state.availableProviders,
                unavailableProviders: state.unavailableProviders,
                locations: state.locations,
                isMapShown: state.isMapShown,
                isLoading: false,
                cities: action._0.cities,
                filters: state.filters,
                locationView: state.locationView,
                userLoginStatus: state.userLoginStatus,
                errorMessage: state.errorMessage
              };
    case "FetchLocations" :
        var res = action._0;
        return {
                locationSelectedIds: state.locationSelectedIds,
                availableProviders: res.availableProviders,
                unavailableProviders: res.unavailableProviders,
                locations: res.locations,
                isMapShown: state.isMapShown,
                isLoading: false,
                cities: state.cities,
                filters: state.filters,
                locationView: state.locationView,
                userLoginStatus: state.userLoginStatus,
                errorMessage: state.errorMessage
              };
    case "SetIsLoading" :
        return {
                locationSelectedIds: state.locationSelectedIds,
                availableProviders: state.availableProviders,
                unavailableProviders: state.unavailableProviders,
                locations: state.locations,
                isMapShown: state.isMapShown,
                isLoading: action._0,
                cities: state.cities,
                filters: state.filters,
                locationView: state.locationView,
                userLoginStatus: state.userLoginStatus,
                errorMessage: state.errorMessage
              };
    case "UpdateFilters" :
        return {
                locationSelectedIds: state.locationSelectedIds,
                availableProviders: state.availableProviders,
                unavailableProviders: state.unavailableProviders,
                locations: state.locations,
                isMapShown: state.isMapShown,
                isLoading: state.isLoading,
                cities: state.cities,
                filters: action._0,
                locationView: state.locationView,
                userLoginStatus: state.userLoginStatus,
                errorMessage: state.errorMessage
              };
    case "UpdateSelectedLocations" :
        return {
                locationSelectedIds: action._0,
                availableProviders: state.availableProviders,
                unavailableProviders: state.unavailableProviders,
                locations: state.locations,
                isMapShown: state.isMapShown,
                isLoading: state.isLoading,
                cities: state.cities,
                filters: state.filters,
                locationView: state.locationView,
                userLoginStatus: state.userLoginStatus,
                errorMessage: state.errorMessage
              };
    case "ToggleLoginStatus" :
        return {
                locationSelectedIds: state.locationSelectedIds,
                availableProviders: state.availableProviders,
                unavailableProviders: state.unavailableProviders,
                locations: state.locations,
                isMapShown: state.isMapShown,
                isLoading: state.isLoading,
                cities: state.cities,
                filters: state.filters,
                locationView: state.locationView,
                userLoginStatus: action._0,
                errorMessage: state.errorMessage
              };
    case "ToggleLocationView" :
        return {
                locationSelectedIds: state.locationSelectedIds,
                availableProviders: state.availableProviders,
                unavailableProviders: state.unavailableProviders,
                locations: state.locations,
                isMapShown: state.isMapShown,
                isLoading: state.isLoading,
                cities: state.cities,
                filters: state.filters,
                locationView: action._0,
                userLoginStatus: state.userLoginStatus,
                errorMessage: state.errorMessage
              };
    case "SetErrorMessage" :
        return {
                locationSelectedIds: state.locationSelectedIds,
                availableProviders: state.availableProviders,
                unavailableProviders: state.unavailableProviders,
                locations: state.locations,
                isMapShown: state.isMapShown,
                isLoading: state.isLoading,
                cities: state.cities,
                filters: state.filters,
                locationView: state.locationView,
                userLoginStatus: state.userLoginStatus,
                errorMessage: action._0
              };
    
  }
}

function useCitiesAndLocations(cities, projectId, filters, locations, availableProviders, unavailableProviders, userLoginStatus) {
  var initialState = React.useMemo((function () {
          return {
                  locationSelectedIds: getSelectedIds(cities),
                  availableProviders: availableProviders,
                  unavailableProviders: unavailableProviders,
                  locations: locations,
                  isMapShown: cities.length === 0,
                  isLoading: false,
                  cities: cities,
                  filters: filters,
                  locationView: "List",
                  userLoginStatus: userLoginStatus,
                  errorMessage: undefined
                };
        }), []);
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var toggleMap = React.useCallback((function () {
          dispatch("ToggleLocationMap");
        }), []);
  var saveDraftProjectAndContinueLater = React.useCallback((function () {
          if (projectId !== undefined) {
            return Url.visit(Routes_Project.index);
          } else {
            return $$Promise.wait(Api.saveColocationDraftProjectAndContinueLater("Colocation", {
                            TAG: "Colocation",
                            _0: "Rfp"
                          }, "Draft"), (function (x) {
                          if (x.TAG === "Ok") {
                            return Url.visit(Routes_Project.index);
                          }
                          SentryLogger.error1({
                                rootModule: "CreateColocationRfpProjectLocations_Container",
                                subModulePath: /* [] */0,
                                value: "useCitiesAndLocations",
                                fullPath: "CreateColocationRfpProjectLocations_Container.useCitiesAndLocations"
                              }, "saveDraftProjectAndContinueLater::saveProjectDraft::Error", [
                                "Error",
                                x._0
                              ]);
                          dispatch({
                                TAG: "SetErrorMessage",
                                _0: "Validation failed, please verify"
                              });
                        }));
          }
        }), []);
  var toggleLoginStatus = React.useCallback((function (status) {
          saveDraftProjectAndContinueLater();
          dispatch({
                TAG: "ToggleLoginStatus",
                _0: status
              });
        }), []);
  var toggleLocationView = React.useCallback((function (view) {
          dispatch({
                TAG: "ToggleLocationView",
                _0: view
              });
        }), []);
  var fetchCities = React.useCallback((function () {
          $$Promise.wait(Api.fetchCitiesForColocationRfpProject(projectId), (function (x) {
                  if (x.TAG === "Ok") {
                    return dispatch({
                                TAG: "FetchCities",
                                _0: x._0
                              });
                  } else {
                    return SentryLogger.error1({
                                rootModule: "CreateColocationRfpProjectLocations_Container",
                                subModulePath: /* [] */0,
                                value: "useCitiesAndLocations",
                                fullPath: "CreateColocationRfpProjectLocations_Container.useCitiesAndLocations"
                              }, "UserProjectMapLocations::FetchCities::Error", [
                                "Error",
                                x._0
                              ]);
                  }
                }));
        }), []);
  var fetchLocations = React.useCallback((function () {
          dispatch({
                TAG: "SetIsLoading",
                _0: true
              });
          $$Promise.wait(Api.fetchLocationsForColocationRfpProject(state.filters, projectId), (function (x) {
                  if (x.TAG === "Ok") {
                    return dispatch({
                                TAG: "FetchLocations",
                                _0: x._0
                              });
                  } else {
                    return SentryLogger.error1({
                                rootModule: "CreateColocationRfpProjectLocations_Container",
                                subModulePath: /* [] */0,
                                value: "useCitiesAndLocations",
                                fullPath: "CreateColocationRfpProjectLocations_Container.useCitiesAndLocations"
                              }, "FetchLocations::Error", [
                                "Error",
                                x._0
                              ]);
                  }
                }));
        }), [state.filters]);
  var saveLocations = React.useCallback((function (locationSelectedIds) {
          dispatch({
                TAG: "SetIsLoading",
                _0: true
              });
          $$Promise.wait(Api.updateLocationsForColocationRfpProject(locationSelectedIds, projectId), (function (x) {
                  if (x.TAG !== "Ok") {
                    return SentryLogger.error1({
                                rootModule: "CreateColocationRfpProjectLocations_Container",
                                subModulePath: /* [] */0,
                                value: "useCitiesAndLocations",
                                fullPath: "CreateColocationRfpProjectLocations_Container.useCitiesAndLocations"
                              }, "UserProjectMapLocations::SaveLocations::Error", [
                                "Error",
                                x._0
                              ]);
                  }
                  dispatch({
                        TAG: "SetIsLoading",
                        _0: false
                      });
                  fetchCities();
                }));
        }), []);
  var onCheckLocation = React.useCallback((function (ids) {
          dispatch({
                TAG: "UpdateSelectedLocations",
                _0: ids
              });
        }), []);
  var setFilters = React.useCallback((function (filters) {
          dispatch({
                TAG: "UpdateFilters",
                _0: filters
              });
        }), []);
  var resetFilters = React.useCallback((function () {
          dispatch({
                TAG: "UpdateFilters",
                _0: CreateColocationRfpProjectLocations_Data_Filter.Input.empty()
              });
        }), []);
  var clearLocations = React.useCallback((function () {
          dispatch("ClearLocations");
        }), []);
  React.useEffect((function () {
          RescriptReactRouter.push("?" + CreateColocationRfpProjectLocations_Data_Filter.Input.toQueryString(state.filters));
          if (state.filters.cities.length !== 0 || state.filters.query.length > 2) {
            fetchLocations();
          }
          if (state.filters.query.length < 3) {
            clearLocations();
          }
          
        }), [state.filters]);
  return {
          isMapShown: state.isMapShown,
          locationView: state.locationView,
          isLoading: state.isLoading,
          cities: state.cities,
          locationSelectedIds: state.locationSelectedIds,
          locations: state.locations,
          availableProviders: state.availableProviders,
          unavailableProviders: state.unavailableProviders,
          filters: state.filters,
          toggleMap: toggleMap,
          toggleLocationView: toggleLocationView,
          fetchCities: fetchCities,
          fetchLocations: fetchLocations,
          saveLocations: saveLocations,
          onCheckLocation: onCheckLocation,
          setFilters: setFilters,
          resetFilters: resetFilters,
          toggleLoginStatus: toggleLoginStatus,
          userLoginStatus: state.userLoginStatus,
          saveDraftProjectAndContinueLater: saveDraftProjectAndContinueLater,
          errorMessage: state.errorMessage
        };
}

export {
  getSelectedIds ,
  reducer ,
  useCitiesAndLocations ,
}
/* Api Not a pure module */
