// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../libs/ID.res.js";
import * as Label from "../../../../styleguide/forms/Label/Label.res.js";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Popover from "../../../../styleguide/components/Popover/Popover.res.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CreateColocationRfpProjectLocations_FilterScss from "./CreateColocationRfpProjectLocations_Filter.scss";

var css = CreateColocationRfpProjectLocations_FilterScss;

function CreateColocationRfpProjectLocations_Filter_More(props) {
  var update = props.update;
  var filters = props.filters;
  var x = filters.certificationIds.length;
  return JsxRuntime.jsxs(Popover.make, {
              children: [
                JsxRuntime.jsx(Popover.Trigger.make, {
                      children: JsxRuntime.jsxs(Button.make, {
                            size: "SM",
                            color: "Gray",
                            className: css.moreFilters,
                            children: [
                              "More Filters",
                              x !== 0 ? JsxRuntime.jsx("span", {
                                      children: String(x),
                                      className: css.badge
                                    }) : null
                            ]
                          })
                    }),
                JsxRuntime.jsx(Popover.Body.make, {
                      position: {
                        TAG: "Below",
                        _0: "RightEdge"
                      },
                      className: css.moreFiltersBody,
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsxs("section", {
                                  children: [
                                    JsxRuntime.jsx("header", {
                                          children: "Certifications"
                                        }),
                                    JsxRuntime.jsx("div", {
                                          children: Belt_Array.map(props.certifications, (function (certification) {
                                                  var id = "user-project-locations-filter--certification-" + ID.toString(certification.id);
                                                  return JsxRuntime.jsxs("div", {
                                                              children: [
                                                                JsxRuntime.jsx(Checkbox.make, {
                                                                      id: id,
                                                                      size: "SM",
                                                                      checked: (function (__x) {
                                                                            return Js_array.includes(certification.id, __x);
                                                                          })(filters.certificationIds),
                                                                      onChange: (function ($$event) {
                                                                          var checked = $$event.target.checked;
                                                                          var certificationIds = checked ? Belt_Array.concat(filters.certificationIds, [certification.id]) : Belt_Array.keep(filters.certificationIds, (function (value) {
                                                                                    return Caml_obj.notequal(value, certification.id);
                                                                                  }));
                                                                          update({
                                                                                providerIds: filters.providerIds,
                                                                                certificationIds: certificationIds,
                                                                                cities: filters.cities,
                                                                                radius: filters.radius,
                                                                                query: filters.query
                                                                              });
                                                                        })
                                                                    }),
                                                                JsxRuntime.jsx(Label.make, {
                                                                      forId: id,
                                                                      className: css.label,
                                                                      children: certification.abbreviation
                                                                    })
                                                              ],
                                                              className: css.filterItem
                                                            }, id);
                                                })),
                                          className: css.certificationsContainer
                                        })
                                  ]
                                }),
                            className: css.moreFiltersContainer
                          })
                    })
              ]
            });
}

var make = CreateColocationRfpProjectLocations_Filter_More;

export {
  css ,
  make ,
}
/* css Not a pure module */
