// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Project from "../../../../models/Project.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RailsContext from "../../../../models/RailsContext.res.js";
import * as CityAndLocations from "../../../../models/CityAndLocations.res.js";
import * as ProviderAsFilter from "../../../../models/ProviderAsFilter.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderForProject from "../../../../models/ProviderForProject.res.js";
import * as LocationForProjectsMap from "../../../../models/LocationForProjectsMap.res.js";
import * as DataCenterCertification from "../../../../models/DataCenterCertification.res.js";
import * as CreateColocationRfpProjectLocations from "./CreateColocationRfpProjectLocations.res.js";
import * as CreateColocationRfpProjectLocations_Data_Filter from "./CreateColocationRfpProjectLocations_Data_Filter.res.js";

function CreateColocationRfpProjectLocations__JsBridge$default(props) {
  var railsContext = props.railsContext;
  var jsProps = props.jsProps;
  var category = Project.Category.fromString(jsProps.category, jsProps.subcategory);
  var railsContext$1 = React.useMemo((function () {
          return RailsContext.fromJson(railsContext);
        }), [railsContext]);
  var projectId = React.useMemo((function () {
          return Belt_Option.map(Caml_option.nullable_to_opt(jsProps.projectId), (function (prim) {
                        return prim;
                      }));
        }), [jsProps.projectId]);
  var locations = React.useMemo((function () {
          return Belt_Array.map(jsProps.locations, LocationForProjectsMap.fromJs);
        }), [jsProps.locations]);
  var cities = React.useMemo((function () {
          return Belt_Array.map(jsProps.cities, CityAndLocations.fromJs);
        }), [jsProps.cities]);
  var availableProviders = React.useMemo((function () {
          return Belt_Array.map(jsProps.availableProviders, ProviderForProject.fromJs);
        }), [jsProps.availableProviders]);
  var unavailableProviders = React.useMemo((function () {
          return Belt_Array.map(jsProps.unavailableProviders, ProviderForProject.fromJs);
        }), [jsProps.unavailableProviders]);
  var certifications = React.useMemo((function () {
          return Belt_Array.map(jsProps.filters.certifications, DataCenterCertification.Filter.fromJs);
        }), [jsProps.filters.certifications]);
  var providers = React.useMemo((function () {
          return Belt_Array.map(jsProps.filters.providers, ProviderAsFilter.fromJs);
        }), [jsProps.filters.providers]);
  var filters = React.useMemo((function () {
          return CreateColocationRfpProjectLocations_Data_Filter.Input.fromJs(jsProps.searchParams);
        }), [jsProps.searchParams]);
  var projectStatus = React.useMemo((function () {
          return Project.Status.fromString(jsProps.status);
        }), [jsProps.status]);
  return JsxRuntime.jsx(CreateColocationRfpProjectLocations.make, {
              projectId: projectId,
              category: category,
              locations: locations,
              cities: cities,
              serverSide: railsContext$1.serverSide,
              mobile: railsContext$1.mobile,
              certifications: certifications,
              availableProviders: availableProviders,
              unavailableProviders: unavailableProviders,
              providersForFilter: providers,
              filters: filters,
              setUserData: props.reduxfunction.setUserData,
              userLoginStatus: railsContext$1.userLoginStatus,
              projectStatus: projectStatus
            });
}

var $$default = CreateColocationRfpProjectLocations__JsBridge$default;

export {
  $$default as default,
}
/* react Not a pure module */
