// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as CityStateCountry from "../../../../models/CityStateCountry.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as AutocompleteCitiesField from "../../../../styleguide/forms/AutocompleteCitiesField/AutocompleteCitiesField.res.js";
import * as CreateColocationRfpProjectLocations_FilterScss from "./CreateColocationRfpProjectLocations_Filter.scss";

var css = CreateColocationRfpProjectLocations_FilterScss;

function CreateColocationRfpProjectLocations_Filter_Cities(props) {
  var update = props.update;
  var filters = props.filters;
  var city = Belt_Array.get(filters.cities, 0);
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(AutocompleteCitiesField.make, {
                    id: "user-project-locations-filter--city",
                    placeholder: "City",
                    onSelected: (function ($$location) {
                        if ($$location !== undefined) {
                          return update({
                                      providerIds: filters.providerIds,
                                      certificationIds: filters.certificationIds,
                                      cities: [$$location],
                                      radius: filters.radius,
                                      query: filters.query
                                    });
                        } else {
                          return update({
                                      providerIds: filters.providerIds,
                                      certificationIds: filters.certificationIds,
                                      cities: [],
                                      radius: filters.radius,
                                      query: filters.query
                                    });
                        }
                      }),
                    className: css.cityInput,
                    onlySearchCities: true,
                    withProducts: false,
                    showSelectedValue: true,
                    initialValue: city !== undefined ? CityStateCountry.toLabel(city) : "",
                    disabled: filters.query.length !== 0
                  }),
              className: css.citiesSearchWrapper
            });
}

var make = CreateColocationRfpProjectLocations_Filter_Cities;

export {
  css ,
  make ,
}
/* css Not a pure module */
