// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../styleguide/components/Link/A.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconServers from "../../../../styleguide/icons/IconServers.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CreateColocationRfpProjectLocations_SelectLocationList from "./CreateColocationRfpProjectLocations_SelectLocationList.res.js";
import * as CreateColocationRfpProjectLocations_MapWithLocations_MatchedProvidersScss from "./CreateColocationRfpProjectLocations_MapWithLocations_MatchedProviders.scss";

var css = CreateColocationRfpProjectLocations_MapWithLocations_MatchedProvidersScss;

function CreateColocationRfpProjectLocations_MapWithLocations_MatchedProviders$NoProviders(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(IconServers.make, {
                      size: "XL"
                    }),
                JsxRuntime.jsx("h3", {
                      children: "No providers",
                      className: css.noProvidersHeading
                    }),
                JsxRuntime.jsxs("p", {
                      children: [
                        "There are no providers that meet the requirements for your project. You can either ",
                        JsxRuntime.jsx(A.make, {
                              href: props.goBackUrl,
                              children: "edit requirements"
                            }),
                        " or change location."
                      ],
                      className: css.noProvidersDescription
                    })
              ],
              className: css.noProviders
            });
}

var NoProviders = {
  make: CreateColocationRfpProjectLocations_MapWithLocations_MatchedProviders$NoProviders
};

function CreateColocationRfpProjectLocations_MapWithLocations_MatchedProviders(props) {
  var data = props.data;
  if (data.availableProviders.length !== 0) {
    return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx("h3", {
                                children: "Matched to Project",
                                className: css.locationCountHeading
                              }),
                          JsxRuntime.jsx("p", {
                                children: "These " + (String(data.availableProviders.length) + (" providers and " + (String(Belt_Array.reduce(data.availableProviders, 0, (function (sum, provider) {
                                                  return sum + provider.locations.length | 0;
                                                }))) + " locations meet the requirements for your project."))),
                                className: css.locationCountDescription
                              })
                        ],
                        className: css.locationsCount
                      }),
                  JsxRuntime.jsx(CreateColocationRfpProjectLocations_SelectLocationList.make, {
                        providers: data.availableProviders,
                        locationSelectedIds: data.locationSelectedIds,
                        onCheckLocation: data.onCheckLocation
                      })
                ]
              });
  } else {
    return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx("h3", {
                              children: "Matched to Project",
                              className: css.locationCountHeading
                            }),
                        className: css.locationsCount
                      }),
                  JsxRuntime.jsx(CreateColocationRfpProjectLocations_MapWithLocations_MatchedProviders$NoProviders, {
                        goBackUrl: props.goBackUrl
                      })
                ]
              });
  }
}

var make = CreateColocationRfpProjectLocations_MapWithLocations_MatchedProviders;

export {
  css ,
  NoProviders ,
  make ,
}
/* css Not a pure module */
