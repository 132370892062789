// Generated by ReScript, PLEASE EDIT WITH CARE


function toggle(x) {
  if (x === "Shown") {
    return "Hidden";
  } else {
    return "Shown";
  }
}

export {
  toggle ,
}
/* No side effect */
