// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Control from "../../components/Control/Control.res.js";
import * as IconClose from "../../icons/IconClose.res.js";
import * as SignInModal from "./SignInModal.res.js";
import * as SignUpModal from "./SignUpModal.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as SignInModalContainerScss from "./SignInModalContainer.scss";

var css = SignInModalContainerScss;

function SignInModalContainer(props) {
  var setUserData = props.setUserData;
  var callback = props.callback;
  var close = props.close;
  var match = React.useReducer((function (param, action) {
          return {
                  currentView: action._0
                };
        }), {
        currentView: "SignIn"
      });
  var dispatch = match[1];
  var match$1 = match[0].currentView;
  var tmp;
  tmp = match$1 === "SignIn" ? JsxRuntime.jsx(SignInModal.make, {
          callback: (function () {
              callback();
            }),
          setUserData: setUserData,
          onSignUp: (function () {
              dispatch({
                    TAG: "SelectView",
                    _0: "SignUp"
                  });
            }),
          close: (function () {
              close();
            }),
          subTitle: props.subTitle
        }) : JsxRuntime.jsx(SignUpModal.make, {
          setUserData: setUserData,
          onSignIn: (function () {
              dispatch({
                    TAG: "SelectView",
                    _0: "SignIn"
                  });
            }),
          onSignUp: (function (param) {
              dispatch({
                    TAG: "SelectView",
                    _0: "SignIn"
                  });
            }),
          close: (function () {
              close();
            }),
          callback: (function () {
              callback();
            })
        });
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      className: css.modalOverlay
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(Control.make, {
                                    className: css.closeOverlayButton,
                                    onClick: (function (param) {
                                        close();
                                      }),
                                    children: JsxRuntime.jsx(IconClose.make, {
                                          title: "Close",
                                          size: "MD",
                                          color: "Gray"
                                        })
                                  }),
                              tmp
                            ],
                            className: css.modalContents
                          }),
                      className: css.signInModal,
                      id: "signInModal"
                    })
              ],
              className: css.signInModalContainer,
              id: "signInModalContainer"
            });
}

var make = SignInModalContainer;

export {
  css ,
  make ,
}
/* css Not a pure module */
