// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CityStateCountry from "../../../../models/CityStateCountry.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Global from "Client/common/global";
import * as CreateColocationRfpProjectLocations_CitiesListScss from "./CreateColocationRfpProjectLocations_CitiesList.scss";

var css = CreateColocationRfpProjectLocations_CitiesListScss;

function CreateColocationRfpProjectLocations_CitiesList$CitiesListItem(props) {
  var onEdit = props.onEdit;
  var city = props.city;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: CityStateCountry.toLabel(city.cityStateCountry),
                                    className: css.title
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("span", {
                                            children: String(city.totalLocations) + (" data centers from " + (String(city.totalProviders) + " provider selected"))
                                          }),
                                      JsxRuntime.jsx("span", {
                                            children: "Edit",
                                            className: css.edit,
                                            onClick: (function (param) {
                                                onEdit(city.cityStateCountry);
                                              })
                                          })
                                    ],
                                    className: css.description
                                  })
                            ],
                            className: css.tileInfo
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("img", {
                                  src: "https://maps.googleapis.com/maps/api/staticmap?center=" + (CityStateCountry.toLabel(city.cityStateCountry) + ("&zoom=13&size=430x100&maptype=roadmap&key=" + Global.GoogleMapAPI))
                                }),
                            className: css.tileMap
                          })
                    ],
                    className: css.tileInfoContainer
                  }),
              className: css.tileContainer
            });
}

var CitiesListItem = {
  make: CreateColocationRfpProjectLocations_CitiesList$CitiesListItem
};

function CreateColocationRfpProjectLocations_CitiesList(props) {
  var onEdit = props.onEdit;
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(Belt_Array.map(props.cities, (function (city) {
                          return JsxRuntime.jsx(CreateColocationRfpProjectLocations_CitiesList$CitiesListItem, {
                                      city: city,
                                      onEdit: onEdit
                                    }, city.cityStateCountry.city);
                        })))
            });
}

var make = CreateColocationRfpProjectLocations_CitiesList;

export {
  css ,
  CitiesListItem ,
  make ,
}
/* css Not a pure module */
